<div class="container">
  <div class="row mt-5" >
    <div class="col-12">
      <mat-card class="p-3 mat-elevation-z6">
        <form class="row pl-5 pr-5" [formGroup]="profileGroup">
          <div class="col-12 d-flex ">
            <label class="img">
              <img id="imageEdit" src="assets/img/icons/plus.svg" alt="">
              <img id="image" src="{{URL}}" alt="">
              <input type="file" #image style="display: none" (change)="handleUpload($event)" [disabled]="!edit">
            </label>
            <div class="col ml-3 p-4 d-flex align-items-center justify-content-center flex-column">
              <div class="row">

                <mat-form-field class="col-12">
                  <mat-label>Nome</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>

                <mat-form-field class="col-12">
                  <mat-label>Telefone</mat-label>
                  <input matInput mask="(00) 0 0000-0000" formControlName="phone">
                </mat-form-field>

                <mat-form-field class="col-4">
                  <mat-label>Data de Nascimento</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="birthday" [disabled]="!edit">
                  <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="!edit"></mat-datepicker-toggle>
                  <mat-datepicker startView="multi-year" #picker [disabled]="!edit"></mat-datepicker>
                </mat-form-field>
                <div class="col-8">
                  <mat-form-field>
                    <input matInput formControlName="password" placeholder="Resetar Senha"
                           [type]="hide ? 'password' : 'text'" [disabled]="!edit">
                    <mat-icon matSuffix (click)="hide = !hide" matTooltip="Alterar visibilidade da senha">
                      {{hide ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                    <mat-hint align="end">
                      <mat-slide-toggle (change)="automaticPassword($event.checked)" [disabled]="!edit">
                        Gerar uma senha automaticamente
                      </mat-slide-toggle>
                    </mat-hint>

                  </mat-form-field>
                </div>

              </div>
            </div>
          </div>
          <div class="col-12 mt-3 d-flex align-items-center justify-content-end">
            <button *ngIf="!edit" (click)="editForm()" mat-raised-button class="m-2" color="primary"> Editar</button>
            <button *ngIf="edit" (click)="initForm()" mat-raised-button class="m-2" color="warn"> Cancelar</button>
            <button *ngIf="edit" (click)="saveData()" mat-raised-button class="m-2" color="primary"> Salvar</button>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
