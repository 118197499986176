import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Amplify, Auth, graphqlOperation} from 'aws-amplify';
import {fromPromise} from 'rxjs/internal-compatibility';
import {getCognitoUser} from '../../../graphql/queries';
import {AmplifyService} from 'aws-amplify-angular';
import {AuthService} from '../../core/auth/auth.service';

@Component({
  selector: 'app-user-self-edit',
  templateUrl: './user-self-edit.component.html',
  styleUrls: ['./user-self-edit.component.scss']
})
export class UserSelfEditComponent implements OnInit {
  @ViewChild('image') imageURL: ElementRef
  imageName = ''
  URL: string | ArrayBuffer = ''
  hide = true
  profileGroup: UntypedFormGroup;
  edit = true
  id = ''
  name= ''
  phone=''

  constructor(private formBuilder: UntypedFormBuilder,
              private amplifyService: AmplifyService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.loadUser()

    this.initForm()
  }

  initForm() {
    this.profileGroup = this.formBuilder.group({
      name: new UntypedFormControl(null),
      phone: new UntypedFormControl(null),
      birthday: new UntypedFormControl(null),
      password: new UntypedFormControl(null)
    })

    this.authService.getCurrentUser().then(item => {
      const id = item.data.getCurrentCognitoUser.id
      fromPromise(this.amplifyService.api().graphql(graphqlOperation(getCognitoUser, {id})))
        .subscribe(
          (resp:any) => {
            const name =  resp.data.getCognitoUser.name
            const phone =  resp.data.getCognitoUser.phone
            this.profileGroup.controls.name.setValue(name)
            this.profileGroup.controls.phone.setValue(phone)
          },
          error =>{
          }
        );
    })

    this.editForm()
  }

  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.URL = reader.result
      let imagelink = reader.result
      this.imageURL.nativeElement.src = reader.result
      imagelink = reader.result.toString();
      imagelink = imagelink.replace(/^data:image\/[a-z]+;base64,/, '');
      this.imageName = imagelink
    };
  }

  private random(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  automaticPassword(enabled: boolean) {
    this.hide = !enabled;

    if (enabled) {
      this.profileGroup.controls.password.disable();
      this.profileGroup.controls.password.setValue(this.random(8));
    } else {
      this.profileGroup.controls.password.enable();
      this.profileGroup.controls.password.setValue('');
    }
  }

  editForm() {
    this.edit = !this.edit
    if (this.edit) {
      this.profileGroup.enable()
    } else {
      this.profileGroup.disable()
    }
  }

  saveData() {
    console.log(this.profileGroup.getRawValue())
  }

  loadUser() {
    this.authService.getCurrentUser().then(item => {
    const id = item.data.getCurrentCognitoUser.id
    fromPromise(this.amplifyService.api().graphql(graphqlOperation(getCognitoUser, {id})))
      .subscribe(
        (resp:any) => {
          console.log(resp)
          this.name =  resp.data.getCognitoUser.name
          this.phone =  resp.data.getCognitoUser.phone
          this.URL = 'https://i.redd.it/q72s9td5npt51.jpg'
        },
        error => console.log(error)
      );
    })
  }

}
