<div class="title">
  <h5 style="color: white">Atualizações do sistema</h5>
</div>
<mat-accordion>

  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Versão 2.3 - 25/01/2021
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list role="list">
      <mat-list-item role="listitem">•Adicionado funcionalidade street view (visão 3D) no mapa da tela de monitoramento.</mat-list-item>
      <mat-list-item role="listitem">•Adicionado responsividade no modal de informações da ocorrência.</mat-list-item>
    </mat-list>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Versão 2.2 - 04/12/2020
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list role="list">
        <mat-list-item role="listitem">•Adicionado campo descrição do veiculo do suspeito no formulario de mpu.</mat-list-item>
        <mat-list-item role="listitem">•Adicionado campo 'complemento' no formulario de cadastro de MPU(suspeito) e Usuário(vitima).</mat-list-item>
        <mat-list-item role="listitem">•Adicionado notificação fora do sistema evocities para informar nova ocorrência criada. (popup) </mat-list-item>
        <mat-list-item role="listitem">•Som de notificação da ocorrência permanente.</mat-list-item>
        <mat-list-item role="listitem">•Parar som de notificação de ocorrência ao clicar.</mat-list-item>
        <mat-list-item role="listitem">•Expandir imagem ao clicar.</mat-list-item>
        <mat-list-item role="listitem">•Modal de visualização da ocorrência completa na tela de monitoramento.</mat-list-item>
        <mat-list-item role="listitem">•Permitir caracteres no campo número de registro.</mat-list-item>
        <mat-list-item role="listitem">•Permitir data escrita nos campos de data e remoção do botão de calendario.</mat-list-item>
      </mat-list>
    </mat-expansion-panel>

  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Versão 2.1 - 05/11/2020
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list role="list">
      <mat-list-item role="listitem">•Atualização da tela de monitoramento</mat-list-item>
      <mat-list-item role="listitem">•Cadastro de usuários mobile (vitima)</mat-list-item>
      <mat-list-item role="listitem">•Cadastro do suspeito</mat-list-item>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>


