import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './core/auth/auth.guard';


// @ts-ignore
// @ts-ignore
const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'occurrences',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/occurrences/occurrences.module').then(m => m.OccurrencesModule)
  },
  {
    path: 'occurrences-grouped',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/occurrences-grouped/occurrences-grouped.module').then(m => m.OccurrencesGroupedModule)
  },
  {
    path: 'ombudsman',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/ombudsman/ombudsman.module').then(m => m.OmbudsmanModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'mobile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/mobile/mobile.module').then(m => m.MobileModule)
  },
  {
    path: 'kanban',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/kanban/kanban.module').then(m => m.KanbanModule)
  },
  {
    path: 'monitoring',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/monitoring/monitoring.module').then(m => m.MonitoringModule)
  },
  {
    path: 'points',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/points/points.module').then(m => m.PointsModule)
  },
  {
    path: 'news',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/news-feed/news-feed.module').then(m => m.NewsFeedModule)
  },
  {
    path: 'my-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/user-self-edit/user-self-edit.module').then(m => m.UserSelfEditModule)
  },
  {
    path: 'calendar-events',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/calendar-events/calendar-events.module').then(m => m.CalendarEventsModule)
  },{
    path: 'analytics-maps',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/analytics-map/analytics-map.module').then(m => m.AnalyticsMapModule)
  },
  {
    path: 'sus',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/sus-queue/sus-queue-module.module').then(m => m.SusQueueModuleModule)
  },
  {
    path: '404', component: PageNotFoundComponent
  },
  {
    path: '**', redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
// @ts-ignore
export class AppRoutingModule {
}
