import {Generic} from './generic';


export class Occurrence extends Generic {
  id: string;
  lat: number;
  lgt: number;
  // tslint:disable-next-line:variable-name
  url_image: string;
  image: string;
  historic;
  // tslint:disable-next-line:variable-name
  occurrences_number_open: string;
  // tslint:disable-next-line:variable-name
  occurrences_number: string;
  // tslint:disable-next-line:variable-name
  last_protocol: string;
  suspect: Occurrence;
  url_image_by_mobile: string
  suspect_veicle: string
  name: string
  address_number: string
  district: string
  cpf: string
  rg: string
  suspect_obs: string
  suspect_address_complement: string
  phone: string
  lng: any;
  type: any;
  operator: boolean;
}



