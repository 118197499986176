import {LayoutModule} from '@angular/cdk/layout';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule} from '@angular/common/http';
import {InjectionToken, NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {CommonModule, DatePipe} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';

import {AppRoutingModule} from './app-routing.module';

import {LoginComponent} from './login/login.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';


import {AuthGuard} from './core/auth/auth.guard';
import {AuthService} from './core/auth/auth.service';
import {MainNavComponent} from './core/main-nav/main-nav.component';

import {MainNavWebComponent} from './core/main-nav/web/main-nav-web.component';
import { ModalInfoComponent } from './core/main-nav/modal-info/modal-info.component';
import {MainNavMobileComponent} from './core/main-nav/mobile/main-nav-mobile.component';

import {AmplifyAngularModule, AmplifyService} from 'aws-amplify-angular';

import {ConnectionCheckService} from './connection-check-service/connection-check.service';

import {CacheService} from './pages/cache/cache.service';
import {FormOccurrenceComponent} from './pages/occurrences/form-occurrence/form-occurrence.component';
import {FormOccurrencesGroupedComponent} from './pages/occurrences-grouped/form-occurrences-grouped/form-occurrences-grouped.component';

import {AppCommon} from './shared/model/common';
import {MaskApplierService, NgxMaskModule} from 'ngx-mask';
import {HeaderPageModule} from './shared/components/header-page/header-page.module';
import {LogoLoadingModule} from './shared/components/logo-loading/logo-loading.module';

import {MpuOccurrencePdfModule} from './shared/components/mpu-occurrence-pdf/mpu-occurrence-pdf.module';

import {RecaptchaModule} from 'ng-recaptcha';
// @ts-ignore
import {UserSelfEditModule} from './pages/user-self-edit/user-self-edit.module';
import {AngularHeatMapModule} from "../lib/angular-heat-map.module";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,

    MainNavComponent,
    MainNavMobileComponent,
    MainNavWebComponent,
    ModalInfoComponent,
  ],
    imports: [

        CommonModule,
        // Material

        BrowserModule,
        HttpClientModule,

        ReactiveFormsModule,
        FormsModule,

        MatProgressBarModule,
        MatCardModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatDialogModule,
        AppRoutingModule,
        AmplifyAngularModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatToolbarModule,
        MatMenuModule,
        MatExpansionModule,

        LayoutModule,
        MatTooltipModule,
        MatDividerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        LogoLoadingModule,
        HeaderPageModule,
        NgbModule,
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: true}),
        NgxMaskModule,
        MpuOccurrencePdfModule,
        MatSelectModule,
        RecaptchaModule,
        UserSelfEditModule,
        AngularHeatMapModule
    ],
  providers: [
    AmplifyService,
    AuthService,
    AuthGuard,
    ConnectionCheckService,
    CacheService,
    AppCommon,
    FormOccurrenceComponent,
    FormOccurrencesGroupedComponent,
    DatePipe,
    MatBottomSheet,
    MaskApplierService,
    // LessonsService,
    // NewsletterService
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'}
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
