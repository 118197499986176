import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserSelfEditComponent} from './user-self-edit.component';

const routes: Routes = [
  {
    path: '',
    component: UserSelfEditComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UserSelfEditRoutingModule { }
