<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer
               class="sidenav"
               fixedInViewport="false"
               [attr.role]="'dialog'"
               [mode]="'over'"
               [opened]="false">
    <mat-toolbar color="primary">
      <img class="logo" src="../../../assets/img/logo-menu.svg">
    </mat-toolbar>

    <mat-nav-list role="navigation" (click)="drawer.toggle()" class="h-content-100">
      <div (click)="expansion.close()">

        <mat-accordion>
          <mat-expansion-panel #expansionList class="shadow">
            <mat-expansion-panel-header (click)="drawer.close()" *ngIf="hasPermission(['list-occurrences'])">
              <mat-panel-title>

                <mat-icon matListIcon svgIcon="mobile-quiz" class="margin-icon {{ router.url === '/occurrences/list'
                                                                               || router.url === '/occurrences/' + occurrence
                                                                               || router.url === '/occurrences-grouped/grouped/' + occurrenceGroup
                                                                               || router.url === '/occurrences-grouped/list-grouped'? 'isActive' : ''}}">
                </mat-icon>

                <span>Lista de ocorrências</span>

              </mat-panel-title>
            </mat-expansion-panel-header>

            <div (click)="drawer.open() && expansionList.close()">

              <a mat-list-item  routerLink="/occurrences/list" routerLinkActive="active-list-item"
                 #rla="routerLinkActive"
                 *ngIf="hasPermission(['list-occurrences'])">
                <mat-icon matListIcon svgIcon="list-occurrences" class="{{ router.url === '/occurrences/list'
                                                                        || router.url === '/occurrences/' + occurrence? 'isActive' : ''}}">
                </mat-icon>

                <h3 mat-line>Lista</h3>
              </a>

              <a mat-list-item routerLink="/occurrences-grouped/list-grouped" routerLinkActive="active-list-item"
                 #rla="routerLinkActive"
                 *ngIf="hasPermission(['list-occurrences'])">
                <mat-icon matListIcon svgIcon="list-occurrences-grouped" class="{{ router.url === '/occurrences-grouped/list-grouped'
                                                                                || router.url === '/occurrences-grouped/grouped/' + occurrenceGroup? 'isActive' : ''}}">
                </mat-icon>
                <h3 mat-line>Lista agrupada</h3>
              </a>

              <a mat-list-item routerLink="/occurrences/form" routerLinkActive="active-list-item"
                 #rla="routerLinkActive"
                 *ngIf="hasPermission(['list-occurrences'])">
                <mat-icon matListIcon svgIcon="list-occurrences-grouped" class="{{ router.url === '/occurrences-grouped/list-grouped'
                                                                                || router.url === '/occurrences-grouped/grouped/' + occurrenceGroup? 'isActive' : ''}}">
                </mat-icon>
                <h3 mat-line>Criar ocorrência</h3>
              </a>

            </div>

          </mat-expansion-panel>
        </mat-accordion>

<!--        <a mat-list-item routerLink="/occurrences/map" routerLinkActive="active-list-item" #rla="routerLinkActive"-->
<!--           *ngIf="hasPermission(['map-occurrences'])">-->
<!--          <mat-icon matListIcon svgIcon="map-occurrences" [color]="rla.isActive && 'primary'"></mat-icon>-->
<!--          <h3 mat-line>Ocorrências no mapa</h3>-->
<!--        </a>-->

        <a mat-list-item routerLink="/monitoring/map" routerLinkActive="active-list-item" #rla="routerLinkActive"
           *ngIf="hasPermission(['map-monitoring'])">
          <mat-icon matListIcon svgIcon="map-monitoring" [color]="rla.isActive && 'primary'"></mat-icon>
          <h3 mat-line>Monitorar Mapa</h3>
        </a>

<!--        <a mat-list-item routerLink="points/map" routerLinkActive="active-list-item" #rla="routerLinkActive"-->
<!--           *ngIf="hasPermission(['map-points'])">-->
<!--          <mat-icon matListIcon svgIcon="map-points" [color]="rla.isActive && 'primary'"></mat-icon>-->
<!--          <h3 mat-line>Pontos no mapa</h3>-->
<!--        </a>-->

        <ng-container *ngIf="hasPermission(['ombudsman'])">
          <mat-divider></mat-divider>
          <h3 matSubheader>Ouvidoria</h3>
        </ng-container>

        <a mat-list-item routerLink="/ombudsman/list" routerLinkActive="active-list-item" #rla="routerLinkActive"
           *ngIf="hasPermission(['ombudsman'])">
          <mat-icon matListIcon svgIcon="ombudsman" class="{{ router.url === '/ombudsman'
                                                           || router.url === '/ombudsman/' + ticket? 'isActive' : ''}}">
          </mat-icon>
          <h3 mat-line>Lista de tickets</h3>
        </a>

        <ng-container *ngIf="hasPermission(['news-feed'])">
          <mat-divider></mat-divider>
          <h3 matSubheader>Noticias</h3>
        </ng-container>

        <a mat-list-item routerLink="/news/form" routerLinkActive="active-list-item" #rla="routerLinkActive"
           *ngIf="hasPermission(['news-feed'])">
          <mat-icon matListIcon svgIcon="news-feed" [color]="rla.isActive && 'primary'"></mat-icon>
          <h3 mat-line>Criar Noticia</h3>
        </a>
      </div>

      <ng-container *ngIf="hasPermission(['mobile-review'])">
        <mat-divider></mat-divider>
        <h3 matSubheader>Relatórios</h3>
      </ng-container>

      <mat-accordion>
        <mat-expansion-panel #expansion class="shadow">
          <mat-expansion-panel-header (click)="drawer.close()" *ngIf="hasPermission(['mobile-review'])">
            <mat-panel-title>

              <mat-icon matListIcon svgIcon="mobile-quiz" class="margin-icon" color="{{ router.url === '/reports/mobile-quiz' ||
         router.url === '/reports/mobile-quiz-notes'? 'primary' : ''}}"></mat-icon>
              <span>Avaliações</span>

            </mat-panel-title>
          </mat-expansion-panel-header>

          <div (click)="drawer.open() && expansion.close()">

            <a mat-list-item routerLink="/reports/mobile-quiz" routerLinkActive="active-list-item"
               #rla="routerLinkActive"
               *ngIf="hasPermission(['mobile-review'])">
              <mat-icon matListIcon svgIcon="mobile-review" [color]="rla.isActive && 'primary'"></mat-icon>
              <h3 mat-line>Indice</h3>
            </a>

            <a mat-list-item routerLink="/reports/mobile-quiz-notes" routerLinkActive="active-list-item"
               #rla="routerLinkActive"
               *ngIf="hasPermission(['mobile-review-notes'])">
              <mat-icon matListIcon svgIcon="mobile-review-notes" [color]="rla.isActive && 'primary'"></mat-icon>
              <h3 mat-line>Relatório</h3>
            </a>

          </div>

        </mat-expansion-panel>
      </mat-accordion>

      <div (click)="expansion.close()">
        <ng-container *ngIf="hasPermission(['mobile-message'])">
          <mat-divider></mat-divider>
          <h3 matSubheader>Aplicativo</h3>
        </ng-container>

        <a mat-list-item routerLink="/mobile/message" routerLinkActive="active-list-item" #rla="routerLinkActive"
           *ngIf="hasPermission(['mobile-message'])">
          <mat-icon matListIcon svgIcon="mobile-message" [color]="rla.isActive && 'primary'"></mat-icon>
          <h3 mat-line>Mensagens</h3>
        </a>

        <ng-container
          *ngIf="hasPermission(['system-user-config', 'offices-config', 'occurrences-type-config', 'mobile-quiz', 'integrations-config'])">
          <mat-divider></mat-divider>
          <h3 matSubheader>Configurações</h3>
        </ng-container>

        <a mat-list-item routerLink="/settings/users" routerLinkActive="active-list-item" #rla="routerLinkActive"
           *ngIf="hasPermission(['system-user-config'])">
          <mat-icon matListIcon svgIcon="account-group" [color]="rla.isActive && 'primary'"></mat-icon>
          <h3 mat-line>Usuários</h3>
        </a>

        <a mat-list-item routerLink="/settings/offices" routerLinkActive="active-list-item" #rla="routerLinkActive"
           *ngIf="hasPermission(['offices-config'])">
          <mat-icon matListIcon svgIcon="office-building" [color]="rla.isActive && 'primary'"></mat-icon>
          <h3 mat-line>Secretarias</h3>
        </a>

        <a mat-list-item routerLink="/settings/occurrences" routerLinkActive="active-list-item" #rla="routerLinkActive"
           *ngIf="hasPermission(['occurrences-type-config'])">
          <mat-icon matListIcon svgIcon="occurrences-type-config" [color]="rla.isActive && 'primary'"></mat-icon>
          <h3 mat-line>Tipos de ocorrências</h3>
        </a>

        <a mat-list-item routerLink="/settings/status-occurrences" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['occurrences-status-config'])">
          <mat-icon matListIcon svgIcon="occurrences-status-config" [color]="rla.isActive && 'primary'"></mat-icon>
          <h3 mat-line>Status de ocorrências</h3>
        </a>

        <a mat-list-item routerLink="/settings/mobile-quiz" routerLinkActive="active-list-item" #rla="routerLinkActive"
           *ngIf="hasPermission(['mobile-quiz'])">
          <mat-icon matListIcon svgIcon="mobile-quiz" [color]="rla.isActive && 'primary'"></mat-icon>
          <h3 mat-line>Questionários</h3>
        </a>

        <a mat-list-item routerLink="/settings/integrations" routerLinkActive="active-list-item" #rla="routerLinkActive"
           *ngIf="hasPermission(['integrations-config'])">
          <mat-icon matListIcon svgIcon="lan" [color]="rla.isActive && 'primary'"></mat-icon>
          <h3 mat-line>Integrações</h3>
        </a>

      </div>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary" class="mat-elevation-z4">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span style="margin: 0 10px;">Evo Cities</span>

      <span class="spacer"></span>

      <button class="left-margin" mat-icon-button (click)="logoutBtn()" matTooltip="Fazer logout">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </mat-toolbar>

    <!-- Add Content Here -->
    <ng-content></ng-content>

  </mat-sidenav-content>
</mat-sidenav-container>
