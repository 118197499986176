import {Injectable} from '@angular/core';

import * as jwt_decode from 'jwt-decode';

import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {fromPromise} from 'rxjs/internal-compatibility';

import {API, graphqlOperation} from 'aws-amplify';
import {AmplifyService} from 'aws-amplify-angular';
import {getCurrentCognitoUser} from '../../../graphql/queries';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private city: string;
  private userId: string;
  public userName: string;
  public userEmail: string;
  public loggedUser = false;
  private userLoggedIn = new Subject<boolean>();
  public permissions = new BehaviorSubject<Permission[]>([]);
  public officesPermission = new BehaviorSubject<IDPermission[]>([]);
  public occurrencesTypesPermission = new BehaviorSubject<IDPermission[]>([]);
  public occurrencesStatus = new BehaviorSubject<{ name: string, enabled: boolean }[]>([]);

  public capchaUrl = 'https://www.google.com/recaptcha/api/siteverify?secret=6Ldx9vgcAAAAAKQst9XZwOwRRCofDXOQnd9VbSKU&response='
  public appkey = '6Ldx9vgcAAAAAPoJQaPDsMQ0L0hACa2l5DisJOUI'

  constructor(private amplifyService: AmplifyService,
              private http : HttpClient) {
    this.userLoggedIn.next(false);
  }

  /**
   * carrega todas variaveis de "ambiente" do sistema
   * @param user UserCognito
   */
  loadUser(user: any) : Promise<any> {
    return new Promise<any>((resolve, reject) =>{
      this.loggedUser = true;

      const token = user.signInUserSession.idToken.jwtToken;
      const tempUser = jwt_decode(token);

      this.userId = tempUser.sub;
      this.city = tempUser['custom:city'];

      fromPromise(this.amplifyService.api().graphql(
        graphqlOperation(getCurrentCognitoUser)))
        .subscribe(
          resp => {
            resolve(resp)
            const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
            const currentCognito: CurrentCognitoPermissions = result.data.getCurrentCognitoUser;
            this.permissions.next(currentCognito.permissions);
            this.officesPermission.next(currentCognito.offices);
            this.occurrencesTypesPermission.next(currentCognito.occurrences_types);
            this.occurrencesStatus.next(currentCognito.occurrences_status);
            this.userName = result.data.getCurrentCognitoUser.name;
            this.userEmail = result.data.getCurrentCognitoUser.email;
          },
          error => reject(error));
    })
  }

  /**
   * carrega todas variaveis de "ambiente" do sistema
   * @param user UserCognito
   */
  loadUsercopy(user: any) : Promise<any> {
    return new Promise<any>((resolve, reject) =>{
      this.loggedUser = true;

      const token = user.signInUserSession.idToken.jwtToken;
      const tempUser = jwt_decode(token);

      this.userId = tempUser.sub;
      this.city = tempUser['custom:city'];

      // @ts-ignore
      fromPromise(API.graphql(
        graphqlOperation(getCurrentCognitoUser)))
        .subscribe(
          resp => {
            resolve(resp)
            const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
            const currentCognito: CurrentCognitoPermissions = result.data.getCurrentCognitoUser;
            this.permissions.next(currentCognito.permissions);
            this.officesPermission.next(currentCognito.offices);
            this.occurrencesTypesPermission.next(currentCognito.occurrences_types);
            this.occurrencesStatus.next(currentCognito.occurrences_status);
            this.userName = result.data.getCurrentCognitoUser.name;
            this.userEmail = result.data.getCurrentCognitoUser.email;
          },
          error => reject(error));
    })
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  clearUser() {
    this.permissions.next([]);
    this.loggedUser = false;
  }

  getCurrentUser() : Promise<any>{
    return new Promise((resolve, reject)=>{
      // @ts-ignore
      fromPromise(API.graphql(graphqlOperation(getCurrentCognitoUser))).subscribe(user =>resolve(user),error => reject(error)  );
    })
  }

  getCapcha(captchaResponse : any): Promise<any> {
    return new Promise<any>((resolve, reject)=>{
      this.http.post(`https://cors-anyware.herokuapp.com/${this.capchaUrl}${captchaResponse}`, {}).subscribe((resp: any) => {
        resolve(resp.success)
      }, error => {
        reject(error)
      })
    })
  }

}

class Permission {
  name: string;
  action: string;
}

class IDPermission {
  id: string;
  name: string;
  action: string;
}

class CurrentCognitoPermissions {
  permissions: Array<Permission>;
  offices: Array<IDPermission>;
  occurrences_types: Array<IDPermission>;
  occurrences_status: Array<{ name: string, enabled: boolean }>;
}
