<!--template web-->
<mat-drawer-container class="sidenav-container ">
  <mat-drawer mode="side" class="side-bar mat-elevation-z4 scroll-bar scroll shadow" opened style="width: 64px;">
    <mat-toolbar color="primary" class="mat-elevation-z1">
      <a routerLink="/home/">
        <img class="logo" src="../../../assets/img/logo-menu-2.svg">
      </a>
    </mat-toolbar> <!-- home -->

    <mat-nav-list role="navigation" class="side-navigation h-content-100">

      <div *ngIf="operator !== 'mariadapenhaciospbm@gmail.com'">


        <a mat-list-item class="divider" routerLink="/monitoring/map" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['map-monitoring'])">
          <mat-icon matListIcon svgIcon="map-monitoring" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Monitorar Ocorrências no Mapa</span></a>

        <a mat-list-item class="divider" routerLink="/analytics-maps/maps" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['heat-map'])">
          <mat-icon matListIcon svgIcon="heat-map" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Mapa de calor</span></a>
        <!-- Monitorar Ocorrências -->

        <a mat-list-item class="divider" routerLink="/occurrences/form" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['list-occurrences'])">
          <mat-icon matListIcon svgIcon="list-occurrences-grouped" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Criar ocorrência</span></a>

        <a style="width: 100%; cursor: pointer" mat-list-item class="divider" routerLink="/ombudsman/list"
           routerLinkActive="active-list-item"
           class="{{ router.url === '/ombudsman' || router.url === '/ombudsman/' + ticket? 'isActive' : ''}}"
           #rla="routerLinkActive" *ngIf="hasPermission(['ombudsman'])">
          <mat-icon matListIcon svgIcon="ombudsman" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Lista de Ouvidorias</span>
        </a> <!-- Lista de Ouvidorias -->


        <a mat-list-item class="divider" routerLink="/news/form" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['news-feed'])">
          <mat-icon matListIcon svgIcon="news-feed" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Postar Notícia</span>
        </a> <!-- Postar Notícia -->

        <div
          class="{{ router.url === '/reports/analytics' || router.url === '/reports/mobile-quiz' || router.url === '/reports/mobile-quiz-notes'? 'isActive' : ''}}">
          <mat-list-item style="width: 100%" class="divider button-width" [matMenuTriggerFor]="menu"
                         *ngIf="hasPermission(['mobile-review'])">
            <mat-icon matListIcon svgIcon="mobile-quiz"
                      class="{{router.url === '/reports/analytics' || router.url === '/reports/mobile-quiz' || router.url === '/reports/mobile-quiz-notes'? 'primary' : ''}}"></mat-icon>
            <span
              class="{{router.url === '/reports/analytics' || router.url === '/reports/mobile-quiz' || router.url === '/reports/mobile-quiz-notes'? 'primary' : ''}}">Avaliações</span>
            <mat-icon matListIcon svgIcon="arrow-drop-down" class="align-arrow"
                      color="{{ router.url === '/reports/analytics' || router.url === '/reports/mobile-quiz' || router.url === '/reports/mobile-quiz-notes'? 'primary' : ''}}"></mat-icon>
          </mat-list-item>
        </div> <!-- btn abrir Avaliações-->

        <mat-menu #menu="matMenu" class="text-justify">
          <div
            style="width: 100%; cursor: pointer; background: #FFFFFF; border-radius: 5px;box-shadow: inset 0 0 5px #21212120">
            <div style="width: 100%; cursor: pointer;" routerLink="/reports/mobile-quiz">
              <a
                routerLink="/reports/mobile-quiz"
                routerLinkActive="active-list-item" #rla="routerLinkActive"
                *ngIf="hasPermission(['mobile-review'])">
                <mat-icon matListIcon svgIcon="mobile-review" class="align-icon"
                          [color]="rla.isActive && 'primary'"></mat-icon>
                <span>Índice de Satisfação</span>
              </a>
            </div>


            <div style="width: 100%; cursor: pointer" routerLink="/reports/mobile-quiz-notes">
              <a style="width: 100%; cursor: pointer"
                 routerLink="/reports/mobile-quiz-notes"
                 routerLinkActive="active-list-item" #rla="routerLinkActive"
                 *ngIf="hasPermission(['mobile-review-notes'])">
                <mat-icon matListIcon svgIcon="mobile-review-notes" class="align-icon"
                          [color]="rla.isActive && 'primary'"></mat-icon>
                <span>Relatório de Satisfação</span>
              </a>
            </div>


            <div style="width: 100%; cursor: pointer" routerLink="/settings/mobile-quiz">
              <a style="width: 100%; cursor: pointer"
                 routerLink="/settings/mobile-quiz"
                 routerLinkActive="active-list-item" #rla="routerLinkActive"
                 *ngIf="hasPermission(['mobile-quiz'])">
                <mat-icon matListIcon svgIcon="engine" class="align-icon"
                          [color]="rla.isActive && 'primary'"></mat-icon>
                <span>Configurar questionario</span>
              </a>
            </div>

            <div style="width: 100%; cursor: pointer" routerLink="/reports/analytics">
              <a style="width: 100%; cursor: pointer"
                 routerLink="/reports/analytics"
                 routerLinkActive="active-list-item" #rla="routerLinkActive"
                 *ngIf="hasPermission(['mobile-quiz'])">
                <mat-icon matListIcon svgIcon="pie-chart" class="align-icon"
                          [color]="rla.isActive && 'primary'"></mat-icon>
                <span>Analise de Resultados</span>
              </a>
            </div> <!-- Analytics -->

          </div>

        </mat-menu> <!-- conteudo avaliaçoes-->

        <a mat-list-item class="divider" routerLink="/mobile/message" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['mobile-message'])">
          <mat-icon matListIcon svgIcon="mobile-message" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Mensagens</span>
        </a> <!--Mensagens-->

        <mat-list-item style="width: 100%" class="button-width divider" [matMenuTriggerFor]="menuEmergency"
                       *ngIf="hasPermission(['map-points'])">
          <mat-icon matListIcon svgIcon="map-points" color="{{ router.url === 'points/map'}}"></mat-icon>
          <span class="{{ router.url === 'points/map' ? 'primary' : ''}}">Pontos importantes</span>
          <mat-icon matListIcon svgIcon="arrow-drop-down" class="align-arrow"
                    color="{{ router.url === '/reports/mobile-quiz' || router.url === '/reports/mobile-quiz-notes'? 'primary' : ''}}"></mat-icon>
        </mat-list-item> <!--Pontos importantes-->

        <mat-menu #menuEmergency="matMenu" class="text-justify">
          <div
            style="width: 100%; cursor: pointer; background: #FFFFFF; border-radius: 5px;box-shadow: inset 0 0 5px #21212120">
            <div style="width: 100%; cursor: pointer;">
              <a
                routerLink="points/map"
                routerLinkActive="active-list-item" #rla="routerLinkActive"
                *ngIf="hasPermission(['map-points'])">
                <mat-icon matListIcon svgIcon="map-points" class="align-icon"
                          [color]="rla.isActive && 'primary'"></mat-icon>
                <span>Pontos importantes</span>
              </a>
            </div>
          </div>


          <!--        <div style="width: 100%; cursor: pointer" routerLink="/settings/emergency-phones">-->
          <!--          <a style="width: 100%; cursor: pointer" matTooltip="Cadastrar numeros de emergência"-->
          <!--             routerLink="/settings/emergency-phones"-->
          <!--             routerLinkActive="active-list-item" #rla="routerLinkActive"-->
          <!--             *ngIf="hasPermission(['emergency-phones'])">-->
          <!--            <mat-icon matListIcon class="align-icon"-->
          <!--                      [color]="rla.isActive && 'primary'">phone</mat-icon>-->
          <!--            <span>Contatos de emergencia</span>-->
          <!--          </a>-->
          <!--        </div>-->

        </mat-menu><!--Pontos importantes-->

        <a mat-list-item class="divider" routerLink="/settings/users" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['system-user-config'])">
          <mat-icon matListIcon svgIcon="account-group" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Configurar Usuários</span>
        </a> <!--Configurar Usuários-->

        <a mat-list-item class="divider" routerLink="/calendar-events/form" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['calendar-events'])">
          <mat-icon matListIcon svgIcon="calendar-events" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Criar eventos</span>
        </a> <!--Configurar Usuários-->



        <div class="{{ router.url.includes('/sus')  ? 'isActive' : ''}}" *ngIf="hasPermission(['sus'])">
          <mat-list-item style="width: 100%" class="divider button-width" [matMenuTriggerFor]="susMenu">
            <mat-icon matListIcon svgIcon="sus"
                      class="{{router.url === '/sus-queue' ? 'primary' : ''}}"></mat-icon>
            <span
              class="{{router.url ===  '/sus'? 'primary' : ''}}">Agendamento SUS</span>
            <mat-icon matListIcon svgIcon="arrow-drop-down" class="align-arrow"
                      color="{{ router.url === '/sus-queue' ? 'primary' : ''}}"></mat-icon>
          </mat-list-item>
        </div> <!-- btn abrir Avaliações-->

        <mat-menu #susMenu="matMenu" class="text-justify">
          <div style="width: 100%; cursor: pointer; background: #FFFFFF; border-radius: 5px;box-shadow: inset 0 0 5px #21212120">
              <div style="width: 100%; cursor: pointer;"
                   routerLink="/sus/queue"
                   routerLinkActive="active-list-item"
                #rla="routerLinkActive">
                <a
                  style="width: 100%;"
                  *ngIf="hasPermission(['mobile-review'])"> <!-- colocar as permiçoes aqui @importante-->
                  <mat-icon matListIcon svgIcon="nurse" class="align-icon"></mat-icon>
                  <span>Atendimento</span>
                </a>
              </div>
                <div style="width: 100%; cursor: pointer"
                     routerLink="sus/health-center"
                     routerLinkActive="active-list-item" #rla="routerLinkActive">
                  <a *ngIf="hasPermission(['mobile-review'])"> <!-- colocar as permiçoes aqui @importante-->
                    <mat-icon matListIcon svgIcon="hospital-icon" class="align-icon"></mat-icon>
                    <span>Postos Cadastrados</span>
                  </a>
                </div>

            </div>
        </mat-menu> <!-- conteudo sus sus-->


        <a mat-list-item class="divider" routerLink="/settings/offices" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['offices-config'])">
          <mat-icon matListIcon svgIcon="office-building" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Configurar Secretarias</span>
        </a> <!--Configurar Secretarias-->

        <a mat-list-item class="divider" routerLink="/settings/occurrences" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['occurrences-type-config'])">
          <mat-icon matListIcon svgIcon="occurrences-type-config" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Configurar Tipos de Ocorrências</span>
        </a> <!--Configurar Tipos de Ocorrências-->

        <a mat-list-item class="divider" routerLink="/settings/status-occurrences" routerLinkActive="active-list-item"
           #rla="routerLinkActive" *ngIf="hasPermission(['occurrences-status-config'])">
          <mat-icon matListIcon svgIcon="occurrences-status-config"
                    class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Configurar Status de Ocorrências</span>
        </a> <!--Configurar Status de Ocorrências-->

        <a mat-list-item class="divider" routerLink="/settings/integrations" routerLinkActive="active-list-item"
           #rla="routerLinkActive"
           *ngIf="hasPermission(['integrations-config'])">
          <mat-icon matListIcon svgIcon="lan" class="{{rla.isActive ? 'primary' : ''}}"></mat-icon>
          <span class="{{rla.isActive ? 'primary' : ''}}">Configurar Integrações</span>
        </a> <!--Configurar Integrações-->

      </div>
    </mat-nav-list>
  </mat-drawer> <!-- Home -->

  <mat-drawer-content>
    <mat-toolbar color="primary" class="mat-elevation-z4">

      <div class="col d-flex justify-content-end" style="width: 100%;">
        <button class="btn-acount" mat-icon-button [matMenuTriggerFor]="belowMenu">
          <div class="img">
            <img src="" alt="">
          </div>
        </button> <!-- button menu estilizado com a foto-->
        <mat-menu #belowMenu="matMenu">
          <div class="space"></div>
          <div class="menu-open">
            <h5 class="mb-3">{{authService.userName}}</h5>

            <span class="mb-3" style="cursor: pointer" routerLink="/my-profile">
                          <mat-icon class="mr-2" svgIcon="pencil"></mat-icon>Editar Perfil
                        </span>
            <!-- Opção de editar perfil habilitar quando criar a tela -->

            <span class="mb-3" style="cursor:pointer;" (click)="openModal()">
              <mat-icon class="mr-2" svgIcon="information"></mat-icon>
              Sobre o sistema
            </span>

            <span class="mb-3" style="cursor:pointer;" (click)="logoutBtn()" matTooltip="Fazer logout">
              <mat-icon class="mr-2">exit_to_app</mat-icon>
              Fazer logout
            </span>


          </div>
        </mat-menu> <!--conteudo do menu depois de aberto-->
      </div>
    </mat-toolbar>

    <div class="site-content">
      <ng-content></ng-content>
    </div>

  </mat-drawer-content>

</mat-drawer-container>


<!--      <mat-menu #menuList="matMenu" class="text-justify">-->
<!--        <div style="width: 100%; cursor: pointer" routerLink="/occurrences/list" class="{{ router.url === '/occurrences/list'-->
<!--                             || router.url === '/occurrences/' + occurrence? 'isActive' : ''}}">-->
<!--          <a matTooltip="Lista"-->
<!--             routerLink="/occurrences/list"-->
<!--             routerLinkActive="active-list-item" #rla="routerLinkActive"-->
<!--             *ngIf="hasPermission(['list-occurrences'])">-->
<!--            <mat-icon matListIcon svgIcon="list-occurrences" class="align-icon"-->
<!--            ></mat-icon>-->
<!--            <span>Lista</span>-->
<!--          </a>-->
<!--        </div>-->

<!--        <ng-container *ngIf="hasPermission(['list-occurrences'])">-->
<!--          <mat-divider></mat-divider>-->
<!--        </ng-container>-->

<!--        <div style="width: 100%; cursor: pointer" routerLink="/occurrences-grouped/list-grouped" class="{{ router.url === '/occurrences-grouped/list-grouped'-->
<!--                             || router.url === '/occurrences-grouped/grouped/' + occurrenceGroup? 'isActive' : ''}}">-->
<!--          <a matTooltip="Lista agrupada"-->
<!--             routerLink="/occurrences-grouped/list-grouped"-->
<!--             routerLinkActive="active-list-item" #rla="routerLinkActive"-->
<!--             *ngIf="hasPermission(['list-occurrences'])">-->
<!--            <mat-icon matListIcon svgIcon="list-occurrences-grouped" class="align-icon"-->
<!--            ></mat-icon>-->
<!--            <span>Lista agrupada</span>-->
<!--          </a>-->
<!--        </div>-->

<!--      </mat-menu>-->

<!--      <div class="{{ router.url === '/occurrences/list' || router.url === '/occurrences/' + occurrence || router.url === '/occurrences-grouped/grouped/' + occurrenceGroup ||-->
<!--         router.url === '/occurrences-grouped/list-grouped'? 'isActive' : ''}}">-->
<!--        <mat-list-item matTooltip="Lista de ocorrências" class="button-width" [matMenuTriggerFor]="menuList"-->
<!--                       *ngIf="hasPermission(['list-occurrences'])">-->

<!--          <mat-icon matListIcon svgIcon="list-occurrences" color="{{ router.url === '/occurrences/list'-->
<!--                                                                  || router.url === '/occurrences/' + occurrence-->
<!--                                                                  || router.url === '/occurrences-grouped/grouped/' + occurrenceGroup-->
<!--                                                                  || router.url === '/occurrences-grouped/list-grouped'? 'primary' : ''}}">-->
<!--          </mat-icon>-->

<!--          <mat-icon matListIcon svgIcon="arrow-right" class="arrow-drop-down" color="{{ router.url === '/occurrences-grouped/list-grouped'-->
<!--                                                                                    || router.url === '/occurrences-grouped/grouped/' + occurrenceGroup-->
<!--                                                                                    || router.url === '/occurrences/' + occurrence-->
<!--                                                                                    || router.url === '/occurrences/list'? 'primary' : ''}}">-->
<!--          </mat-icon>-->

<!--        </mat-list-item>-->
<!--      </div>-->


<!--      <mat-menu #menuVaccination="matMenu" class="text-justify">-->
<!--        <div style="width: 100%; cursor: pointer" routerLink="/vac/map" class="{{ router.url === 'vac/map'-->
<!--                             || router.url === '/vac/'? 'isActive' : ''}}">-->
<!--          <a matTooltip="Pontos de vacinação"-->
<!--             routerLink="/vac/map"-->
<!--             routerLinkActive="active-list-item" #rla="routerLinkActive"-->
<!--             *ngIf="hasPermission(['vaccination-points'])">-->
<!--            <mat-icon matListIcon svgIcon="vaccination-points" class="align-icon"-->
<!--            ></mat-icon>-->
<!--            <span>Pontos de vacinação</span>-->
<!--          </a>-->
<!--        </div>-->

<!--        <ng-container *ngIf="hasPermission(['vaccination-points'])">-->
<!--          <mat-divider></mat-divider>-->
<!--        </ng-container>-->

<!--        <div style="width: 100%; cursor: pointer" routerLink="/settings/user-priority" class="{{ router.url === '/settings/user-priority'? 'isActive' : ''}}">-->
<!--          <a matTooltip="Configurar prioridades do usuario"-->
<!--             routerLink="/settings/user-priority"-->
<!--             routerLinkActive="active-list-item" #rla="routerLinkActive"-->
<!--             *ngIf="hasPermission(['user-priority-status-config'])">-->
<!--            <mat-icon matListIcon svgIcon="user-priority-status-config" class="align-icon"></mat-icon>-->
<!--            <span>prioridades do usuario</span>-->
<!--          </a>-->
<!--        </div>-->

<!--      </mat-menu>-->
<!--      <div class="{{ router.url === '/vac/map' || router.url === '/settings/user-priority'? 'isActive' : ''}}">-->
<!--        <mat-list-item matTooltip="Vacidadão" class="button-width" [matMenuTriggerFor]="menuVaccination"-->
<!--                       *ngIf="hasPermission(['vaccination-points'])">-->

<!--          <mat-icon matListIcon svgIcon="vaccine" color="{{ router.url === '/vac/map'? 'primary' : ''}}">-->
<!--          </mat-icon>-->

<!--          <mat-icon matListIcon svgIcon="arrow-right" class="arrow-drop-down" color="{{ router.url === '/occurrences/list'? 'primary' : ''}}">-->
<!--          </mat-icon>-->

<!--        </mat-list-item>-->
<!--      </div>-->
