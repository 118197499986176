import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserSelfEditComponent} from './user-self-edit.component';
import {UserSelfEditRoutingModule} from './user-self-edit-routing.module';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NgxMaskModule} from 'ngx-mask';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";



@NgModule({
  declarations: [
    UserSelfEditComponent
  ],
  imports: [
    CommonModule,
    UserSelfEditRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskModule.forRoot(),
    MatDatepickerModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
  ]
})
export class UserSelfEditModule { }
