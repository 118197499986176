<div class="background" >
  <mat-progress-bar *ngIf="loading" mode="indeterminate" style="position: absolute"></mat-progress-bar>

  <div class="container">
    <div class="row justify-content-center center-row">
      <div class="col">
        <mat-card style="max-width: 400px;">
          <mat-card-header class="justify-content-center">
            <mat-card-title class="text-center">Login</mat-card-title>
            <mat-card-subtitle class="text-center">Sistema Evo Cities</mat-card-subtitle>
          </mat-card-header>

          <mat-card-content >
            <form [formGroup]="loginForm" (ngSubmit)="onSubmitForm()" novalidate *ngIf="status == 0">
              <mat-form-field>
                <input type="text" matInput formControlName="email" placeholder="Acesso" [autofocus]="true" appLowerCase
                       required/>
                <mat-error>Acesso obrigatório.</mat-error>
              </mat-form-field>

              <mat-form-field>
                <input matInput type="password" formControlName="password" placeholder="Senha" minlength="8" required/>
                <mat-error>Senha obrigatória.</mat-error>
              </mat-form-field>

              <mat-form-field *ngIf="needChangePassword">
                <input matInput type="password" formControlName="newPassword" placeholder="Nova Senha" minlength="8"
                       [autofocus]="needChangePassword"/>
                <mat-hint>Crie uma nova senha de acesso.</mat-hint>
                <mat-error>Senha obrigatória com mínimo 8 caracteres.</mat-error>
              </mat-form-field>
              <div class="row">
                <div class="col text-left">
                  {{ errorMessage }}
                </div>
                <div class="col text-right">
                  <button mat-flat-button type="submit" color="accent"
                          [disabled]="loginForm.invalid || loading">
                    {{ needChangePassword ? 'Salvar' : 'Entrar' }}
                  </button>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col text-center">
                  <button mat-raised-button (click)="status = 2" class="mat-elevation-z0"><u style="color: #0277bd">Esqueci minha Senha</u></button>
                </div>
              </div>
            </form>
            <form [formGroup]="forgetPasswordForm" (ngSubmit)="onRedefinePassword()" novalidate *ngIf="status == 2">
              <mat-form-field>
                <input type="text" matInput formControlName="email" placeholder="Acesso" [autofocus]="true" appLowerCase
                       required/>
                <mat-error>email obrigatório.</mat-error>
              </mat-form-field>

              <div class="row">
                <div class="col text-right">
                  <button  mat-flat-button type="submit" color="accent"
                           [disabled]="loginForm.invalid || loading">
                    Envar Código de confimação
                  </button>
                </div>
              </div>
            </form>

            <form [formGroup]="confirmationCodeForm" (ngSubmit)="onConfirmCode()" novalidate *ngIf="status == 3">
              <mat-form-field>
                <input type="text" matInput formControlName="code" placeholder="Código de confirmação" [autofocus]="true" appLowerCase
                       required/>
                <mat-error>Código obrigatório.</mat-error>
              </mat-form-field>

              <div class="row">
                <div class="col text-right">
                  <button  mat-flat-button type="submit" color="accent"
                           [disabled]="confirmationCodeForm.invalid ">
                           Confirmar
                  </button>
                </div>
              </div>

            </form>
          </mat-card-content>
        </mat-card>

        <div class="row justify-content-center pt-2">

          <div class="font-size"><p>Versão: {{version}}</p></div>

        </div>
      </div>
    </div>
  </div>
</div>

